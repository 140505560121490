import { forwardRef } from "react";
import { CFormInput } from "@coreui/react";

export const PhoneInput = forwardRef(function PhoneInput({
  id = "phoneFormControlInput",
  label = "Telefone",
  placeholder = "55 9 9999 9999",
  ariaDescribedBy = "phoneFormControlInputHelpInline",
  ...rest
}, ref) {
  return (
    <CFormInput
      ref={ref}
      size="lg"
      type="phone"
      id={id}
      label={label}
      placeholder={placeholder}
      aria-describedby={ariaDescribedBy}
      {...rest}
    />
  );
});