import { useCallback, useEffect, useState } from "react";

export function useDisplaySize() {
  const getDisplaySize = useCallback(() => {
    const { innerWidth } = window;
    return innerWidth >= 1200
      ? "xl"
      : innerWidth >= 850
        ? "lg"
        : innerWidth >= 600
          ? "md"
          : "xs";
  }, []);

  const [displaySize, setDisplaySize] = useState(getDisplaySize());

  const updateDisplaySize = useCallback(() => {
    setDisplaySize(() => getDisplaySize());
  }, [setDisplaySize, getDisplaySize]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      updateDisplaySize();
    });
    return () => {
      window.removeEventListener("resize", () => {
        updateDisplaySize();
      });
    };
  }, [updateDisplaySize]);

  return { displaySize };
}