import { CButton } from "@coreui/react";
import { useCallback } from "react";


export function SignInBtn() {
  const handleSignInClick = useCallback(() => {
    localStorage.setItem("loggedIn", "true");
    window.location.assign("/");
  }, []);
  return (
    <CButton size="lg" color="dark" variant="outline" onClick={handleSignInClick}>
      Entrar
    </CButton>
  );
}