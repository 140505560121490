import { forwardRef } from "react";
import { CFormInput } from "@coreui/react";

export const FullNameInput = forwardRef(function FullNameInput({
  id = "nameFormControlInput",
  label = "Nome Completo",
  placeholder = "Ex.: João Almeida",
  ariaDescribedBy = "nameFormControlInputHelpInline",
  ...rest
}, ref) {
  return (
    <CFormInput
      ref={ref}
      size="lg"
      type="text"
      id={id}
      label={label}
      placeholder={placeholder}
      aria-describedby={ariaDescribedBy}
      {...rest}
    />
  );
});