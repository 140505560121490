import { useRef } from "react";
import { CCol, CForm } from "@coreui/react";
import { FullNameInput } from "../../components/FullNameInput";
import { PhoneInput } from "../../components/PhoneInput";
import { PasswordInput } from "../../components/PasswordInput";
import { EmailInput } from "../../components/EmailInput";


export function RegisterForm() {
  const fullNameInputRef = useRef();
  const phoneInputRef = useRef();
  const passwordInputRef = useRef();
  const passwordConfirmInputRef = useRef();
  const emailInputRef = useRef();
  return (
    <CForm data-testid="sign-up">
      <CCol xs="12" className="mb-2">
        <FullNameInput ref={fullNameInputRef} />
      </CCol>
      <CCol xs="12" className="mb-2">
        <PhoneInput ref={phoneInputRef} />
      </CCol>
      <CCol xs="12" className="mb-2">
        <EmailInput ref={emailInputRef} />
      </CCol>
      <CCol xs="12" className="mb-2">
        <PasswordInput ref={passwordInputRef} />
      </CCol>
      <CCol xs="12" className="mb-2">
        <PasswordInput 
          ref={passwordConfirmInputRef}
          label="Confirme sua senha"
          id="passwordConfirmFormControlInput"
          ariaDescribedBy="passwordConfirmFormControlInputHelpInline"
        />
      </CCol>
    </CForm>
  );
}