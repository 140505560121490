
import { SignInView } from "../views/SignIn";

const Error = () => {
  return (
    <div>
      <h1>Error</h1>
      <p>Sorry, an unexpected error has occurred.</p>
    </div>
  );
}

export const signInRoute = {
  path: "/",
  element: <SignInView />,
  errorElement: <Error />,
}