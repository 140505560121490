
import { Root } from "../components/Root";
import { HomeView } from "../views/Home";
// import { UserView } from "../views/User";
import { GlobalProvider } from "../providers/global.contex";


const Error = () => {
  return (
    <div>
      <h1>Error</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <a href="/">Go to home</a>
    </div>
  );
}

export const rootRoute = {
  path: "/",
  element: <GlobalProvider>
    <Root />
  </GlobalProvider>,
  errorElement: <Error />,
  children: [
    {
      path: "/",
      element: <HomeView />,
    },
  ],
}