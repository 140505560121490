import { CButton } from "@coreui/react";
import  React, { useCallback } from "react";

export function RedirectBtn({
  to = "/",
  label = "Cancelar",
  size = "lg",
  color = "secondary",
  variant = "outline",
  ...rest
}) {
  const handleClick = useCallback(() => window.location.assign(to), []);
  return (
    <CButton
      {...rest}
      size={size}
      color={color}
      variant={variant}
      onClick={handleClick}
    >
      {label}
    </CButton>
  );
}