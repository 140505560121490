import { CButton } from "@coreui/react";
import { useCallback } from "react";

export function SignUpBtn() {
  const handleSignUpClick = useCallback(() => {
    window.location.assign("/cadastro");
  }, []);
  return (
    <CButton size="lg" color="secondary" variant="outline" onClick={handleSignUpClick}>
      Cadastre-se já!
    </CButton>
  );
}