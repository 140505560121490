import { Fragment } from "react";
import { SidebarItem } from "./SidebarItem";


export function SidebarItemGroupItem({ children, fontColor }) {
  return children.map(child => (
    <Fragment key={child.id}>
      <SidebarItem
        isChild
        path={child.path}
        icon={child.icon}
        label={child.name}
        fontColor={fontColor}
      />
    </Fragment>
  ));
}