
import { useAuth } from "./hooks/useAuth";
import { RouterProvider } from "react-router-dom";
import { publicRouter, privateRouter } from "./router";
import "./App.css";

export function App() {
  const { isLoggedIn } = useAuth();
  return (
    <div data-testid="app">
      <RouterProvider
        router={isLoggedIn ? privateRouter : publicRouter}
        fallbackElement={<div>Loading...</div>}
        future={{ v7_startTransition: true }}
      />
    </div>
  );
}
