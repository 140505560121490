import { forwardRef } from "react";
import { CFormInput } from "@coreui/react";

export const EmailInput = forwardRef(function EmailInput({
  id = "emailFormControlInput",
  label = "Endereço de e-mail",
  placeholder = "nome@exemplo.com.br",
  ariaDescribedBy = "emailFormControlInputHelpInline",
  ...rest
}, ref) {
  return (
    <CFormInput
      ref={ref}
      size="lg"
      type="email"
      id={id}
      label={label}
      placeholder={placeholder}
      aria-describedby={ariaDescribedBy}
      {...rest}
    />
  );
});