// import { mockup } from "./mockup";
// import { GlobalContext } from "../../providers/global.contex";
import { toast } from "react-toastify";
import { useCallback, useState } from "react";
import {
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CFormInput,
  CFormLabel,
  CInputGroup,
  CRow
} from "@coreui/react";

export function HomeView() {
  const [pdfFile, setPdfFile] = useState(null);
  const [json, setJson] = useState({});

  const planify = useCallback(json => {
    return Object.keys(json)
      .reduce((previous, current) => {
        if (typeof json[current] !== "object") {
          return { ...previous, [current]: json[current] }
        }
        return {
          ...previous,
          ...Object.keys(json[current])
            .reduce((p, c) => {
              return {
                ...p,
                [c]: json[current][c]
              }
            }, {})
        }
      }, {});
  }, []);

  const handlePdfInputChange = useCallback(({ target }) => {
    setPdfFile(target.files[0]);
    const formData = new FormData();
    formData.append('pdfFile', target.files[0]);
    fetch("https://api.gustavoilhamorais.dev.br/ocr/pdf-to-json/2", {
      method: "POST",
      body: formData
    })
      .then(response => response.json())
      .then(response => {
        if (response.statusText === "error") {
          toast(response.message, {
            type: "error",
          });
          if (response.errors?.length) {
            toast(<ul>{response.errors.map((e, i) => <li key={i + e}>{e}</li>)}</ul>, {
              type: "error"
            });
          }
          return;
        }
        setJson(planify(JSON.parse(response.data.content)));
        toast(response.message, {
          type: "success"
        });
      });
  }, [planify]);

  return (
    <CContainer data-testid="home">
      <CCol xs="12" className="mb-5 mt-3">
        <CCard className="bg-success text-light">
          <CCardBody>
            <h1>
              PDF Processor
            </h1>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol xs="12">
        <CCard style={{
          height: "150px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        }}>
          <CCardBody>
            <CFormInput
              type="file"
              id="pdf-input"
              style={{ marginTop: "33px" }}
              filename={pdfFile?.name || ""}
              onChange={handlePdfInputChange}
            />
          </CCardBody>
        </CCard>
      </CCol>
      <CCol className="mt-5">
        <CCard>
          <CCardBody>
            <CRow>
              {
                Object.keys(json)
                  .map(key => json[key] && typeof json[key] === "object" ? null : (
                    <CCol xs="4" key={`${key}-col`}>
                      <CFormLabel htmlFor={`${key}-input`} style={{ color: "green" }}>
                        {key}
                      </CFormLabel>
                      <CInputGroup>
                        <CFormInput id={`${key}-input`} defaultValue={json[key]} value={json[key]} />
                      </CInputGroup>
                    </CCol>
                  ))
              }
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CContainer>
  );
}
