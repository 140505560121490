import { useContext }     from "react";
import { Header }         from "../Header";
import { Footer }         from "../Footer";
import { Sidebar }        from "../Sidebar";
import classNames         from "classnames";
import { CCol, CRow }     from "@coreui/react";
import { ToastContainer } from "react-toastify";
import { Outlet }         from "react-router-dom";
import { GlobalContext }  from "../../providers/global.contex";
import 'react-toastify/dist/ReactToastify.css';

export function Root() {
  const {
    isSidebarVisible,
    setIsSidebarVisible,
    isDarkTheme,
    setIsDarkTheme
  } = useContext(GlobalContext);
  const contentTheme = classNames(
    isDarkTheme ? "bg-dark" : "bg-light",
    isDarkTheme ? "text-light" : "text-dark"
  )
  return (
    <div data-testid="root" style={{ height: '100vh'}}>
      <ToastContainer />
      <Header
        setVisible={setIsSidebarVisible}
        theme={isDarkTheme ? "dark" : "light"}
        toggleTheme={() => setIsDarkTheme(!isDarkTheme)}
      />
      <CRow>
        <Sidebar
          isVisible={isSidebarVisible}
          onVisibleChange={setIsSidebarVisible}
          theme={isDarkTheme ? "dark" : "light"}
        />
        <CCol
          className={contentTheme}
          style={{
            height: "100vh",
            overflow: "auto",
            scrollBehavior: "smooth"
          }}
        >
          <Outlet />
        </CCol>
        <Footer />
      </CRow>
    </div>
  );
}