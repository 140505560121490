import { forwardRef } from "react";
import { CFormInput } from "@coreui/react";

export const PasswordInput = forwardRef(function PasswordInput({
  id = "passwordFormControlInput",
  label = "Senha",
  placeholder = "************",
  ariaDescribedBy = "passwordFormControlInputHelpInline",
  ...rest
}, ref) {
  return (
    <CFormInput
      ref={ref}
      size="lg"
      type="password"
      id={id}
      label={label}
      placeholder={placeholder}
      aria-describedby={ariaDescribedBy}
      {...rest}
    />
  );
});