
import { CCol, CFooter } from "@coreui/react";

export function Footer() {
  return (
    <CCol xs="12" data-testid="app-footer">
      <CFooter>
        <p>&copy; {new Date().getFullYear()}. TOBY.ai® Todos os direitos reservados.</p>
      </CFooter>
    </CCol>
  );
}