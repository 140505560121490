
import styled from "styled-components";
import logo from "../../assets/logo.png";
import { RegisterForm } from "./RegisterForm";
import { SubmitBtn } from "./SubmitBtn";
import { RedirectBtn } from "../../components/RedirectBtn";
import {
  CButton,
  CCard,
  CCardBody,
  CCardFooter,
  CCol,
  CContainer,
  CImage,
  CRow
} from "@coreui/react";

const Row = styled(CRow)`
  margin-top: 5%;
`;

const Card = styled(CCard)`
  max-width: 590px;
  margin: 0 auto;
`;

const Image = styled(CImage)`
  margin-bottom: 2rem;
`;


export function SignUpView() {
  return (
    <CContainer fluid>
      <Row className="justify-content-center">
        <CCol xs={12} className="d-flex justify-content-center">
          <Image fluid src={logo} alt="Logo" />
        </CCol>
        <CCol xs="12" md="8" lg="6">
          <Card className="bg-light">
            <CCardBody>
              <RegisterForm />
              <CButton className="float-end" color="link">Esqueceu sua senha?</CButton>
            </CCardBody>
            <CCardFooter className="d-grid gap-2 mt-3">
              <SubmitBtn />
              <RedirectBtn label="Voltar" />
            </CCardFooter>
          </Card>
        </CCol>
      </Row>
    </CContainer>
  );
}