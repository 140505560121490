import { createContext, useEffect, useState } from "react";
import { useDisplaySize } from "../hooks/useDisplaySize";
import { useNavigate } from "react-router-dom";

export const GlobalContext = createContext({
  navigate: () => { },
  displaySize: "",
  isSidebarVisible: false,
  setIsSidebarVisible: () => { },
  isDarkTheme: false,
  setIsDarkTheme: () => { }
});

export const { Provider, Consumer } = GlobalContext;

export function GlobalProvider({ children }) {
  const [isDarkTheme, setIsDarkTheme] = useState(false);

  const navigate = useNavigate();

  const { displaySize } = useDisplaySize();
  const [isSidebarVisible, setIsSidebarVisible] = useState(() => displaySize !== "xs");

  useEffect(() => {
    setIsSidebarVisible(displaySize !== "xs");
  }, [displaySize, setIsSidebarVisible]);

  return (
    <Provider value={{
      navigate,
      displaySize,
      isSidebarVisible,
      setIsSidebarVisible,
      isDarkTheme,
      setIsDarkTheme
    }}>
      {children}
    </Provider>
  );
}