
import logo from "../../assets/logo.png";
import logoDark from "../../assets/logo_dark.png";
import { CHeaderBrand } from "@coreui/react";
import { CCol, CHeader, CImage } from "@coreui/react";
import { FaBars, FaLightbulb } from "react-icons/fa6";

export function Header({ setVisible, theme = "light", toggleTheme }) {
  return (
    <CHeader
      data-testid="app-header"
      className={`bg-${theme} align-items-center text-light`}
    >
      <CCol>
        <CHeaderBrand
          onClick={() => setVisible(visible => !visible)}
          className={`text-${theme === "light" ? "dark" : "light"}`}
        >
          <span className="pointer">
            <FaBars className="mb-1" />&nbsp;Menu
          </span>
        </CHeaderBrand>
      </CCol>
      <CCol className="text-center">
        <CImage width="120" src={theme === "light" ? logo : logoDark} alt="Logo" />
      </CCol>
      {/* <CCol className="text-end">
        <span className={`pointer text-${theme === "light" ? "dark" : "light"}`} onClick={toggleTheme}>
          <FaLightbulb className="mb-1" />&nbsp;Modo {theme === "light" ? "Escuro" : "Claro"}
        </span>
      </CCol> */}
    </CHeader >
  );
}