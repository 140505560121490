
import { SignUpView } from "../views/SignUp";

const Error = () => {
  return (
    <div>
      <h1>Error</h1>
      <p>Sorry, an unexpected error has occurred.</p>
    </div>
  );
}

export const signUpRoute = {
  path: "/cadastro",
  element: <SignUpView />,
  errorElement: <Error />,
}