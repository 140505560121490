import { CForm, CFormInput } from "@coreui/react";


export function CredentialsForm() {
  return (
    <CForm className="mt-5 mb-2">
      <CFormInput
        size="lg"
        type="email"
        id="emailFormControlInput"
        label="Endereço de e-mail"
        placeholder="nome@exemplo.com.br"
        aria-describedby="emailFormControlInputHelpInline"
        className="mb-3"
      />
      <CFormInput
        size="lg"
        type="password"
        id="passwordFormControlInput"
        label="Senha"
        placeholder="********"
        aria-describedby="passwordFormControlInputHelpInline"
      />
    </CForm>
  );
}