
export const sidebarItems = [
  {
    "id": "home",
    "name": "Início",
    "path": "/",
    "icon": "HomeIcon",
  },
  {
    "id": "personal-data",
    "name": "Dados Pessoais",
    "path": "/dados-pessoais",
    "icon": "UserIcon",
    "children": [
      {
        "id": "update",
        "name": "Editar",
        "path": "/dados-pessoais/editar",
        "icon": "UpdateIcon",
      }
    ]
  },
];