import { Fragment } from "react";
import classNames from "classnames";
import styled from "styled-components";
import { SidebarItem } from "./SidebarItem";
import { sidebarItems } from "./sidebarItems";
import { SidebarItemGroup } from "./SidebarItemGroup";
import { SidebarItemGroupItem } from "./SidebarItemGroupItem";
import {
  CNavTitle,
  CSidebar,
  CSidebarBrand,
  CSidebarHeader,
  CSidebarNav,
} from "@coreui/react";

const StyledSidebar = styled(CSidebar)`
  height: 100%;
`;

export function Sidebar({ isVisible, onVisibleChange, theme = "light" }) {
  const fontColor = classNames(theme === "light" ? "text-dark" : "text-light");
  return (
    <StyledSidebar
      expand="lg"
      visible={isVisible}
      data-testid="sidebar"
      style={{ height: "100vh" }}
      onVisibleChange={onVisibleChange}
      className={`bg-${theme} border-end`}
    >
      <CSidebarHeader className="border-bottom">
        <CSidebarBrand className={fontColor}>Sidebar</CSidebarBrand>
      </CSidebarHeader>
      <CSidebarNav>
        <CNavTitle className={fontColor}>Nav Title</CNavTitle>
        {sidebarItems.map((item) =>
          <Fragment key={item.id}>
            {!item.children ? (
              <SidebarItem
                key={item.id}
                path={item.path}
                icon={item.icon}
                label={item.name}
                fontColor={fontColor}
              />
            ) : (
              <SidebarItemGroup
                icon={item.icon}
                label={item.name}
                fontColor={fontColor}
                children={
                  <SidebarItemGroupItem
                    children={item.children}
                    fontColor={fontColor}
                  />
                }
              />
            )}
          </Fragment>
        )}
      </CSidebarNav>
    </StyledSidebar>
  );
}