import { createBrowserRouter } from "react-router-dom";
import { signInRoute } from "./routes/signIn.route";
import { signUpRoute } from "./routes/signUp.route";
import { rootRoute } from "./routes/root.route";


const authenticatedRoutes = [
  rootRoute
];

const unauthenticatedRoutes = [
  signInRoute,
  signUpRoute
];

export const publicRouter = createBrowserRouter(unauthenticatedRoutes);
export const privateRouter = createBrowserRouter(authenticatedRoutes);