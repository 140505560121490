
import { CNavGroup } from "@coreui/react";
import { SidebarItemIcon } from "./SidebarItemIcon";

export function SidebarItemGroup({ icon, fontColor, label, children }) {
  return (
    <CNavGroup toggler={
      <>
        <SidebarItemIcon iconName={icon} />&nbsp;
        <span className={fontColor}>
          {label}
        </span>
      </>
    }>
      {children}
    </CNavGroup >
  );
}