
import styled, { css } from "styled-components";
import {
  FaUser, FaCircleXmark, FaPenClip, FaHouse } from "react-icons/fa6";

const iconStyles = css`
  border-radius: 50%;
  padding: 8px;
  color: white;
  background-color: #4dc374;
  margin-right: 5px;
  height: 35px;
  width: 35px;
`;
const UserIcon = styled(FaUser)`
  ${iconStyles}
`;
const HomeIcon = styled(FaHouse)`
  ${iconStyles}
`;
const UpdateIcon = styled(FaPenClip)`
  ${iconStyles}
`;

export function SidebarItemIcon({ iconName }) {
  switch (iconName) {
    case "UserIcon":
      return <UserIcon />;
    case "HomeIcon":
      return <HomeIcon />;
    case "UpdateIcon":
      return <UpdateIcon />;
    default:
      return <FaCircleXmark />;
  }
}