import { CButton } from "@coreui/react";
import { useCallback, useContext } from "react";
import { SidebarItemIcon } from "./SidebarItemIcon";
import { GlobalContext } from "../../providers/global.contex";

export function SidebarItem({ isChild = false, path, fontColor, icon, label }) {
  const { navigate } = useContext(GlobalContext);
  const handleSidebarItemClick = useCallback(() => {
    navigate(path);
  }, [navigate, path]);
  return (
    <div style={{ paddingLeft: isChild ? "30px" : "" }}>
      <CButton
        className={fontColor}
        onClick={handleSidebarItemClick}
        color={fontColor === "text-light" ? "dark" : "light"}
      >
        <SidebarItemIcon iconName={icon} />&nbsp;{label}
      </CButton>
    </div>
  );
}