import { CButton } from "@coreui/react";
import { useCallback } from "react";

export function SubmitBtn() {
  const handleSubmitClick = useCallback(() => {
    alert("Cadastrado com sucesso!");
  }, []);
  return (
    <CButton color="primary" size="lg" onClick={handleSubmitClick}>
      Criar conta
    </CButton>
  );
}